var render = function () {
  var _vm$promo, _vm$promo2, _vm$promo$description, _vm$promo3, _vm$promo4, _vm$promo5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1270px",
      "mx": "auto",
      "my": ['0', '16px'],
      "h": "auto",
      "bg": "#FFF",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['16px 25px', '30px 20px'],
      "display": "flex",
      "flex-direction": ['column', 'row'],
      "align-items": ['center', 'unset'],
      "gap": "20px",
      "justify-content": "center"
    }
  }, [!_vm.isLoadData ? _c('c-box', {
    attrs: {
      "display": "flex",
      "justify-content": "center",
      "width": "100%",
      "max-width": "500px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": ((_vm$promo = _vm.promo) === null || _vm$promo === void 0 ? void 0 : _vm$promo.imageContent) || ((_vm$promo2 = _vm.promo) === null || _vm$promo2 === void 0 ? void 0 : _vm$promo2.image),
      "alt": "dietela",
      "w": "100%",
      "max-width": ['400px', '500px'],
      "max-height": ['400px', '500px'],
      "min-width": "200px",
      "min-height": "200px",
      "object-fit": "contain"
    }
  })], 1) : _vm._e(), !_vm.isLoadData ? _c('c-box', {
    attrs: {
      "padding": ['0', '20px'],
      "display": "flex",
      "flex-direction": "column",
      "gap": "16px",
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" " + _vm._s(_vm.promo.title || "-") + " ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "14px",
      "size-desktop": "18px"
    }
  }, _vm._l((_vm$promo$description = _vm.promo.description) === null || _vm$promo$description === void 0 ? void 0 : _vm$promo$description.split('\\n'), function (item) {
    return _c('c-box', {
      key: item
    }, [_vm.htmlRegex.test(item) ? _c('c-text', {
      domProps: {
        "innerHTML": _vm._s(item)
      }
    }) : _c('c-text', {
      attrs: {
        "as": "span"
      }
    }, [_vm._v(" " + _vm._s(item) + " "), _c('br')])], 1);
  }), 1), _c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "10px",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "height": ['20px', '25px'],
      "width": ['20px', '25px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-timer.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1), _c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "color": "primary.400",
      "display": ['block', 'none']
    }
  }, [_vm._v(" " + _vm._s(_vm.formatConditionalDate.mobile || '-') + " ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "color": "primary.400",
      "display": ['none', 'block']
    }
  }, [_vm._v(" " + _vm._s(_vm.formatConditionalDate.desktop || '-') + " ")])], 1), !!_vm.promo.usage ? _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "14px",
      "size-desktop": "18px",
      "background-color": "#FDF9BC",
      "padding": "4px 10px"
    }
  }, [_vm._v(" Cara Penggunaan ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "margin-top": "8px"
    }
  }, _vm._l(_vm.reformatData(((_vm$promo3 = _vm.promo) === null || _vm$promo3 === void 0 ? void 0 : _vm$promo3.usage) || ''), function (item) {
    return _c('c-box', {
      key: item
    }, [Array.isArray(item) ? _c('c-box', _vm._l(item, function (v, vidx) {
      return _c('c-box', {
        key: vidx,
        staticStyle: {
          "margin-left": "25px",
          "text-align": "justify"
        },
        attrs: {
          "as": "ul"
        }
      }, [_c('c-box', {
        attrs: {
          "as": "li"
        }
      }, [_c('c-box', {
        domProps: {
          "innerHTML": _vm._s(v)
        }
      })], 1)], 1);
    }), 1) : _c('c-box', {
      staticStyle: {
        "text-align": "justify"
      }
    }, [_c('c-box', {
      domProps: {
        "innerHTML": _vm._s(item)
      }
    })], 1)], 1);
  }), 1)], 1) : _vm._e(), !!_vm.promo.terms ? _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "14px",
      "size-desktop": "18px",
      "background-color": "#FDF9BC",
      "padding": "4px 10px"
    }
  }, [_vm._v(" Syarat Dan Ketentuan ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "margin-top": "8px"
    }
  }, _vm._l(_vm.reformatData(((_vm$promo4 = _vm.promo) === null || _vm$promo4 === void 0 ? void 0 : _vm$promo4.terms) || ''), function (item) {
    return _c('c-box', {
      key: item
    }, [Array.isArray(item) ? _c('c-box', _vm._l(item, function (v, vidx) {
      return _c('c-box', {
        key: vidx,
        staticStyle: {
          "margin-left": "25px",
          "text-align": "justify"
        },
        attrs: {
          "as": "ul"
        }
      }, [_c('c-box', {
        attrs: {
          "as": "li"
        }
      }, [_c('c-box', {
        domProps: {
          "innerHTML": _vm._s(v)
        }
      })], 1)], 1);
    }), 1) : _c('c-box', {
      staticStyle: {
        "text-align": "justify"
      }
    }, [_c('c-box', {
      domProps: {
        "innerHTML": _vm._s(item)
      }
    })], 1)], 1);
  }), 1)], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('BaseButton', {
    attrs: {
      "border-radius": "1000px",
      "padding": "15px 40px",
      "size": "medium"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._v(" " + _vm._s(((_vm$promo5 = _vm.promo) === null || _vm$promo5 === void 0 ? void 0 : _vm$promo5.buttonText) || 'Ambil Promomu Sekarang') + " ")])], 1)], 1) : _vm._e()], 1), _vm.promoHighlight.length > 0 ? _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: _vm.chakra,
      expression: "chakra"
    }],
    attrs: {
      "max-width": "900px",
      "mx": "auto",
      "px": ['0', '1rem'],
      "mt": ['1rem', '0']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px', '20px'],
      "font-weight": "500",
      "color": "#111",
      "mb": ['8px', '0px'],
      "text-align": "left",
      "padding-left": "12px"
    }
  }, [_vm._v(" Promo Lainnya ")]), _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '10px 10px',
          '@media(max-width: 767px)': {
            padding: '0 0 0 10px'
          }
        }
      },
      expression: "{\n        '.slick-slide': {\n          padding: '10px 10px',\n          '@media(max-width: 767px)': {\n            padding: '0 0 0 10px',\n          },\n        },\n      }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.promoHighlight, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "bg-color": "white",
        "border-radius": "12px",
        "overflow": "hidden",
        "as": "a",
        "box-shadow": ['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)'],
        "max-w": ['150px', '100%']
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickPromo(item);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": item.image ? item.image : 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039',
        "alt": "dietela",
        "w": "100%"
      }
    }), _c('c-text', {
      attrs: {
        "p": ['8px 16px', '8px 16px'],
        "font-size": "['14px', '18px']",
        "font-weight": "500",
        "color": "#111",
        "text-transform": "capitalize",
        "is-truncated": true
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1);
  }), 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }