<template>
  <c-box
    width="100%"
  >
    <c-box
      w="100%"
      max-width="1270px"
      mx="auto"
      :my="['0', '16px']"
      h="auto"
      bg="#FFF"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :p="['16px 25px', '30px 20px']"
      display="flex"
      :flex-direction="['column','row']"
      :align-items="['center','unset']"
      gap="20px"
      justify-content="center"
    >
      <c-box
        v-if="!isLoadData"
        display="flex"
        justify-content="center"
        width="100%"
        max-width="500px"
      >
        <c-image
          :src="promo?.imageContent || promo?.image"
          alt="dietela"
          w="100%"
          :max-width="['400px','500px']"
          :max-height="['400px','500px']"
          min-width="200px"
          min-height="200px"
          object-fit="contain"
        />
      </c-box>
      <c-box
        v-if="!isLoadData"
        :padding="['0', '20px']"
        display="flex"
        flex-direction="column"
        gap="16px"
        width="100%"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
        >
          {{ promo.title || "-" }}
        </BaseText>
        <BaseText
          size-mobile="14px"
          size-desktop="18px"
        >
          <c-box
            v-for="(item) in promo.description?.split('\\n')"
            :key="item"
          >
            <c-text
              v-if="htmlRegex.test(item)"
              v-html="item"
            />
            <c-text
              v-else
              as="span"
            >
              {{ item }} <br>
            </c-text>
          </c-box>
        </BaseText>
        <c-box
          display="flex"
          gap="10px"
          align-items="center"
        >
          <c-box
            :height="['20px','25px']"
            :width="['20px','25px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-timer.svg')"
              height="100%"
              width="100%"
              fill="#008C81"
            />
          </c-box>
          <BaseText
            size-mobile="14px-2"
            size-desktop="16px"
            color="primary.400"
            :display="['block', 'none']"
          >
            {{ formatConditionalDate.mobile || '-' }}
          </BaseText>
          <BaseText
            size-mobile="14px-2"
            size-desktop="16px"
            color="primary.400"
            :display="['none', 'block']"
          >
            {{ formatConditionalDate.desktop || '-' }}
          </BaseText>
        </c-box>
        <c-box v-if="!!promo.usage">
          <BaseText
            size-mobile="14px"
            size-desktop="18px"
            background-color="#FDF9BC"
            padding="4px 10px"
          >
            Cara Penggunaan
          </BaseText>
          <BaseText
            size-mobile="14px-2"
            size-desktop="16px"
            margin-top="8px"
          >
            <c-box
              v-for="(item) in reformatData(promo?.usage || '')"
              :key="item"
            >
              <c-box v-if="Array.isArray(item)">
                <c-box
                  v-for="(v, vidx) in item"
                  :key="vidx"
                  as="ul"
                  style="margin-left: 25px; text-align: justify;"
                >
                  <c-box as="li">
                    <c-box v-html="v" />
                  </c-box>
                </c-box>
              </c-box>
              <c-box
                v-else
                style=" text-align: justify;"
              >
                <c-box v-html="item" />
              </c-box>
            </c-box>
          </BaseText>
        </c-box>
        <c-box
          v-if="!!promo.terms"
        >
          <BaseText
            size-mobile="14px"
            size-desktop="18px"
            background-color="#FDF9BC"
            padding="4px 10px"
          >
            Syarat Dan Ketentuan
          </BaseText>
          <BaseText
            size-mobile="14px-2"
            size-desktop="16px"
            margin-top="8px"
          >
            <c-box
              v-for="(item) in reformatData(promo?.terms || '')"
              :key="item"
            >
              <c-box v-if="Array.isArray(item)">
                <c-box
                  v-for="(v, vidx) in item"
                  :key="vidx"
                  as="ul"
                  style="margin-left: 25px; text-align: justify;"
                >
                  <c-box as="li">
                    <c-box v-html="v" />
                  </c-box>
                </c-box>
              </c-box>
              <c-box
                v-else
                style=" text-align: justify;"
              >
                <c-box v-html="item" />
              </c-box>
            </c-box>
          </BaseText>
        </c-box>
        <c-box
          display="flex"
          justify-content="center"
        >
          <BaseButton
            border-radius="1000px"
            padding="15px 40px"
            size="medium"
            @click="handleClick"
          >
            {{ promo?.buttonText || 'Ambil Promomu Sekarang' }}
          </BaseButton>
        </c-box>
      </c-box>
    </c-box>
    <c-box
      v-if="promoHighlight.length > 0"
      v-chakra="chakra"
      max-width="900px"
      mx="auto"
      :px="['0', '1rem']"
      :mt="['1rem', '0']"
    >
      <c-text
        :font-size="['18px', '20px']"
        font-weight="500"
        color="#111"
        :mb="['8px', '0px']"
        text-align="left"
        padding-left="12px"
      >
        Promo Lainnya
      </c-text>
      <VueSlickCarousel
        v-chakra="{
          '.slick-slide': {
            padding: '10px 10px',
            '@media(max-width: 767px)': {
              padding: '0 0 0 10px',
            },
          },
        }"
        v-bind="settings"
      >
        <c-box
          v-for="(item, index) in promoHighlight"
          :key="index"
          bg-color="white"
          border-radius="12px"
          overflow="hidden"
          as="a"
          :box-shadow="['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)']"
          :max-w="['150px', '100%']"
          @click="handleClickPromo(item)"
        >
          <c-image
            :src="
              item.image
                ? item.image
                : 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039'
            "
            alt="dietela"
            w="100%"
          />
          <c-text
            :p="['8px 16px', '8px 16px']"
            font-size="['14px', '18px']"
            font-weight="500"
            color="#111"
            text-transform="capitalize"
            :is-truncated="true"
          >
            {{ item.title }}
          </c-text>
        </c-box>
      </VueSlickCarousel>
    </c-box>
  </c-box>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { mapGetters, mapActions } from 'vuex'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { formatDateV2 } from '@/utils/format-date'

export default {
  name: 'PromoBerlangsung',
  components: {
    BaseButton,
    BaseText,
    VueSlickCarousel,
  },
  props: {
    chakra: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isLoadData: false,
      promo: {
        image: '',
        imageContent: '',
        title: '',
        startDate: '',
        startEnd: '',
        description: '',
        usage: '',
        terms: '',
        link: '',
      },
      settings: {
        arrows: false,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        draggable: false,
        swipeToSlide: false,
        touchMove: false,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              draggable: true,
              swipeToSlide: true,
              touchMove: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: true,
            },
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      promoHighlight: 'general/promoHighlight',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    formatConditionalDate(){
      const start = formatDateV2(this.promo.startDate)
      const end = formatDateV2(this.promo.startEnd)
      return {
        mobile: `${start.split(' ')[0]} - ${end}`,
        desktop: `${start} - ${end}`,
      }
    },
    htmlRegex() {
      return /<[^>]+>/
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        if (route.params.id) {
          this.isLoadData = true
          const tess = await this.getPromoDetail(route.params.id)
          this.promo.image = tess.data?.image
          this.promo.imageContent = tess.data?.imageContent
          this.promo.title = tess.data?.title
          this.promo.startDate = tess.data?.startDate
          this.promo.startEnd = tess.data?.endDate
          this.promo.description = tess.data?.description
          this.promo.usage = tess.data?.usage
          this.promo.terms = tess.data?.terms
          this.promo.link = tess.data?.link
          this.promo.buttonText = tess.data?.buttonText
          this.isLoadData = false
        }
      },
    },
  },
  async mounted() {
    if (this.promoHighlight.length === 0) {
      this.loadPromoHighlight()
    }
  },
  methods: {
    ...mapActions({
      loadPromoHighlight: 'general/getPromoHighlight',
      getPromoDetail: 'general/getPromoDetail',
    }),
    handleClick() {
      // note: special case for promo id = 38854928-501c-4441-9e9d-7db942cc758d add meta pixel
      if (this.$route.params.id === '38854928-501c-4441-9e9d-7db942cc758d') {
        window.fbq('track', 'ChatWA')
      }

      const isExternalLink = this.promo.link && this.promo.link.startsWith('http')
      if (isExternalLink && this.promo.buttonText) {
        window.open(this.promo.link, '_blank')
        return
      }

      if (!this.isAuthenticated) {
        this.$router.push({ name: 'auth', query: { redirect: this.promo?.link || '/' } })
        return
      }

      this.$router.push(this.promo?.link || '/')
    },
    reformatData(_str) {
      /**
       * example: reformatData
       * const input = "- tes \\n- tes \\n- tes \\n tes \\n- tes \\n- tes \\n- tes"
       * const output = [
       *   [
       *     'tes',
       *     'tes',
       *     'tes',
       *   ],
       *   'tes',
       *   [
       *     'tes',
       *     'tes',
       *     'tes',
       *   ],
       * ]
       * */
      return _str.split('\\n').reduce((acc, curr) => {
        const _curr = curr.trim()
        if (_curr[0] === '-') {
          if (acc[acc.length-1] && typeof acc[acc.length-1] === 'object') {
            acc[acc.length-1].push(_curr.slice(2))
          } else {
            acc.push([_curr.slice(2)])
          }
        } else {
          acc.push(_curr)
        }
        return acc
      }, [])
    },
    handleClickPromo(item) {
      const isExternalLink = item.link && item.link.startsWith('http')
      if (isExternalLink && !item.buttonText) {
        window.open(item.link, '_blank')
        return
      }

      this.$router.push({
        name: 'client.promo',
        params: { id: item.id },
      })
    },
  },
}
</script>
